import { useEffect } from 'react';
import './../App.css';

export default function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>
                        <img src='/img/logo_white03.png' />
                    </div>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'>
                            상호 : 에이치로드 엔터테인먼트 | 사업장소재지 :  서울시 성동구 연무장길 17 | 대표자명 : 박효명
                        <br/>
                            사업자등록번호 : 825-19-01910
                        <br/>
                            상담문의: 홈페이지 참조
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            <span>©</span> 에이치로드 엔터테인먼트. All Rights Reserved.
                        </div>
                    </div>
                    <div className='footer__law-con'>
                        <a href='/license'>오픈소스 라이센스</a>
                    </div>
                    <div style={{height:'60px'}}></div>
                </div>
            </div>
        </>
    )
}