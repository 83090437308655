import { useEffect, useState } from 'react';
import './../../../App.css';

export default function Introduction(){
    const [clickBtn, setClickBtn] = useState(false);
    const showList = () => {
        setClickBtn(!clickBtn);
    }

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con' style={{background: 'url(/img/test05.jpg)'}}>
                    <div>COMPANY</div>
                </div>

                <div className='detail-page__btn'>
                    <ul className='detail-page__btn-con'>
                        <li className='detail-page__btn-home'><a href='/'>
                            <img src= '/icons/home.svg' decoding="async"/>
                        </a></li>
                        {/* 현재 페이지 메인메뉴 */}
                        <li className='detail-page__btn-menu'><a>COMPANY</a></li>
                        <li className={`detail-page__btn-submenu + ${ clickBtn ? 'overflow-visible' : ''}`} onClick={showList}>
                            {/* 현재 페이지 서브메뉴 */}
                            <a>회사소개&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&#8744;</span>
                            </a>
                            <ul>
                                <li><a href='/company/introduction'>회사소개</a></li>
                                <li><a href='/company/ourservice'>서비스안내</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className='detail-page__con'>
                    <div className='introduction__logo-wrap' style={{fontFamily: 'Nanum Myeongjo'}}>
                        H ROAD
                    </div>
                    <div className='introduction__txt'>
                        안녕하세요. 웨딩전문 이벤트회사 에이치로드 웨딩입니다.<br/><br/>
                        국내 유일 웨딩플레이 디렉터로서 신랑신부에게 일생에 단 한번 뿐인 
                        감동적인 결혼식을 만들어드리도록 다양한 분야에서 최선을 다하고 있습니다. 웨딩 플레이 디렉터에 대해서 궁금하실 텐데요. 이는 결혼 예식의 순서와(사회, 신랑신부 입장 퍼포먼스, 축가 및 댄스 공연 연출, 연예인 섭외) 전문촬영 & 편집으로 전체적인 웨딩을 만들어주는 사람을 의미합니다.<br/><br/>
                        예식 전 미팅을 통해 원하시는 컨셉에 대한 컨설팅까지 진행하고 있는데요. 두분에게 가장 잘 어울리는 결혼식의 분위기를 만들어드립니다. 무대 연출 분야에 대한 20년의 경력을 바탕으로 최고의 결혼식을 연출합니다.<br/><br/>
                        그렇기에 특별한 결혼식을 원하는 분들이라면 편하게 저희에게 문의해주시길 바라겠습니다. 저희 에이치로드는 합리적인 금액과 최고의 실력으로 멋진 결과를 만들어드립니다.<br/><br/>
                        지금도 에이치로드를 만난 고객님들은 정성어린 후기를 남겨주고 계십니다. 수많은 리뷰 중에서도 가장 많이 차지하는 내용은 바로 신랑신부 뿐 아니라 멀리서 오신 하객분들까지 행복함을 전달한다는 점인데요. 그 비결로는 저희가 하나의 웨딩을 위해서 진정성을 다해서 준비하고 있기 때문이지 않을까 싶습니다. 저희는 그 결과 고객님들이 100% 만족하는 결과를 만들어 냅니다.<br/><br/>
                        인생에서 한 번 뿐인 소중한 결혼식, 저희 에이치로드와 더욱 특별하게 연출해보세요. 저희는 신랑신부님께 잊지못할 행복한 시간을 전해드리겠습니다. 감사합니다.<br/><br/>
                        에이치로드 드림.
                    </div>

                </div>
            </div>
        </>
    )
}