import React, { useEffect, useState } from "react";
import {HashLoader} from "react-spinners";

export default function Loading(){

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timerLoading = setTimeout(() => {
            setLoading(true)
        }, 2000)
        return(
            loading? clearTimeout(timerLoading) : console.log(loading)
        )
    }, [])


    return(
        <>
            <div className={`loading ${loading ? 'loading-after' : ''}`}>
                <div className="loading-txt">H Road Ent.</div>
                <HashLoader color="#fff" />
            </div>
        </>
    )
}