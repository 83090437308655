import { useEffect, useState } from 'react';
import './../../../App.css';


export default function Portfolio(){
    const [clickBtn, setClickBtn] = useState(false);
    const showList = () => {
        setClickBtn(!clickBtn);
    }

    const portfolioData = [
        ['01', 'https://youtu.be/o_F4nZ7ddiw'],
        ['02', 'https://youtu.be/X2qo370SvD0'],
        ['03', 'https://youtu.be/1CX2smhfhJ0?si=t7wzeTe_FD8wN0bb'],
        ['04', 'https://youtu.be/w03vWlcabHg?si=LE2ApTdSQjD5-6Bt'],
        ['05', 'https://youtu.be/e21VFJuLQOM'],
        ['06', 'https://youtu.be/qTjXKHbw3kA'],
        ['07', 'https://youtu.be/7ksg2t_eZQ0?si=9IJtRmt3Ze-lradd'],
        ['08', 'https://youtu.be/78glNJS94pQ'],
        ['09', 'https://youtu.be/3RNVeuGeOwY?si=bFhlmdvwrZpxzZBm'],
        ['10', 'https://youtu.be/b31q78Wx14U?si=H5y51Jp13shvIUnf'],
        ['11', 'https://youtu.be/awRg5IRAAJI'],
        ['12', 'https://youtu.be/7t9tpyIXsLM?si=j4iUQEdKEXiF_eKn'],
    ]

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con' style={{background: 'url(/img/test09.jpg)'}}>
                    <div>PORTFOLIO</div>
                </div>

                <div className='detail-page__btn'>
                    <ul className='detail-page__btn-con'>
                        <li className='detail-page__btn-home'><a href='/'>
                            <img src= '/icons/home.svg' decoding="async"/> 
                        </a></li>
                        {/* 현재 페이지 메인메뉴 */}
                        <li className='detail-page__btn-menu'><a>PORTFOLIO</a></li>
                        <li className={`detail-page__btn-submenu + ${ clickBtn ? 'overflow-visible' : ''}`} onClick={showList}>
                            {/* 현재 페이지 서브메뉴 */}
                            <a>포트폴리오&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&#8744;</span>
                            </a>
                            <ul>
                                <li><a href='/portfolio/portfolio'>포트폴리오</a></li>
                                <li><a href='https://www.youtube.com/channel/UC2Edi1ZsZ_ywd30Tu3vE2zQ'>유튜브</a></li>
                                <li><a href='https://www.instagram.com/sunpy.wedding'>인스타그램</a></li>
                                <li><a href='https://blog.naver.com/sunpys_lifedance'>블로그</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__title-con'>
                        <div className='detail-page__title-main'>포트폴리오</div>
                        <div className='detail-page__title-sub'>에이치로드의 기록을 살펴보세요</div>
                        <div className='txt-line'></div>
                    </div>

                        <div className='intro-portfolio__con' style={{padding:'0'}}>
                            {/* hw. 그리드 안에 vimeo 영상이 들어가게 */}
                            <ul className='intro-portfolio__grid-con'>
                                {
                                    portfolioData.map((a, i) => {
                                        return <GridItem a={a} i={i} />
                                    })
                                }
                            </ul>
                            <div className='intro-portfolio__btn-wrap'>
                                <span><a href='https://www.youtube.com/channel/UC2Edi1ZsZ_ywd30Tu3vE2zQ'>MORE &nbsp;&#8250;</a></span>
                            </div>
                        </div>
                    
                    
                    
                </div>
            </div>
        </>
    )
}

function GridItem(props){    

    return (
        <>
            <li className='intro__grid-item'>
                <a href={props.a[1]} >
                    <div className='intro__img-box' style={{backgroundImage:'url(/img/pf' + props.a[0] + '.jpg)'}} ></div>
                    {/* 호버 시에 나타나는 화면 */}
                    <div className='intro__hover-box'>
                        <img src= '/icons/video.png' decoding="async"/>
                    </div>
                </a>
            </li>    
        </>
    )
}