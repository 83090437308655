import { useEffect, useState } from 'react';
import {useScroll} from '../../../components/useScroll/useScroll';
import './../../../App.css';

export default function WeddingSong(){
    const [clickBtn, setClickBtn] = useState(false);
    const showList = () => {
        setClickBtn(!clickBtn);
    }

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con' style={{background: 'url(/img/test04.jpg)'}}>
                    <div>SERVICE</div>
                </div>

                <div className='detail-page__btn'>
                    <ul className='detail-page__btn-con'>
                        <li className='detail-page__btn-home'><a href='/'>
                            <img src= '/icons/home.svg' decoding="async"/>
                        </a></li>
                        {/* 현재 페이지 메인메뉴 */}
                        <li className='detail-page__btn-menu'><a>SERVICE</a></li>
                        <li className={`detail-page__btn-submenu + ${ clickBtn ? 'overflow-visible' : ''}`} onClick={showList}>
                            {/* 현재 페이지 서브메뉴 */}
                            <a>감동축가&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&#8744;</span>
                            </a>
                            <ul>
                                <li><a href='/service/weddingsong'>감동축가</a></li>
                                <li><a href='/service/weddingmc'>센스사회</a></li>
                                <li><a href='/service/weddingcamera'>현장촬영</a></li>
                                <li><a href='/service/weddingdirecting'>컨셉디렉팅</a></li>
                                <li><a href='/service/weddingevent'>이벤트</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__title-con'>
                        <div className='detail-page__title-main'>감동축가</div>
                        <div className='detail-page__title-sub'>특별한 그룹축가로 더욱 풍성하게</div>
                        <div className='txt-line'></div>
                    </div>
                    
                    <div className='service__con'>
                        <img src='/img/ourservice_wedding.jpg' />
                        <div className='service__txt'>
                            결혼식은 모든 순서마다 갖고있는 의미가 있죠. 그중에서도 축가의 중요성은 크다고 말할 수 있습니다. 그 이유는 웨딩의 클라이맥스의 시점에 가장 큰 감동을 주는 시간이기 때문입니다.<br/><br/>
                            에이치로드는 1인 축가 뿐만 아니라, 그룹축가
                            까지 다양한 서비스를 제공합니다. 저희는 신랑신부님이 원하신다면 한 무대에 수 십 명까지 다수가 함께 주인공을 축하하는 퍼포먼스를 보여드립니다.<br/><br/>
                            저희 만의 특별한 축가로 인해 웨딩홀 내에 감동을 더할 수 있습니다. 또한 에이치로드의 축가팀은 진정성있는 축가로 신랑신부님께 마음을 전달합니다.<br/><br/>
                            그리고 에이치로드는 무대연출 분야에 대해 20년의 경력을 갖춘 팀 답게 하객들의 반응을 자연스럽게 이끌어내는 데에 특화되어 있습니다. 축가팀 뿐 만 아니라 홀에 있는 모든 사람이 신랑신부에게 축하하는 분위기를 만들어내도록 리딩합니다. 특별한 웨딩송을 원하시나요? 저희와 함께해보세요.
                        </div>
                    </div>
                    <div className='service__header-con'>
                        <div className='service__header-txt'>
                            축가무대의 종류는<br/>무엇이 있을까요?
                        </div>
                    </div>
                    <div className='service__content-con'>
                        <div className='service__content-txt'>
                            <div className={`service__content-txt-item 
                            + ${useScroll('.service__content-txt-item:nth-child(1)', 0.65, 0.35).isShow ? 'opacity-1' : 'opacity-point3'}
                            // 모바일
                            + up--start-mo + ${useScroll('.service__content-txt-item:nth-child(1)', 0.90).isShow ? 'up--end1-mo' : ''}`}
                            >
                                <div className='service__img-wrap-mo'>
                                    <img src= '/img/song01.jpg' decoding="async"/>
                                </div>
                                <div className='service__content-txt-title '>
                                    트로트 축하무대
                                </div>
                                <div className='service__content-txt-sub'>
                                    유쾌한 트로트에 맞춰 신랑신부를 위해 전문적인 퍼포먼스를 보여드립니다. 특히나 노래의 특성상 양가 부모님과 하객 모두 즐길 수 있는 시간입니다.
                                </div>
                                <div className='arrow'></div>
                            </div>
                            <div className={`service__content-txt-item 
                            + ${useScroll('.service__content-txt-item:nth-child(2)', 0.65, 0.35).isShow ? 'opacity-1' : 'opacity-point3'}
                            // 모바일
                            + up--start-mo + ${useScroll('.service__content-txt-item:nth-child(2)', 0.90).isShow ? 'up--end1-mo' : ''}`}
                            >
                                <div className='service__img-wrap-mo'>
                                    <img src= '/img/song02.jpg' decoding="async"/>
                                </div>
                                <div className='service__content-txt-title'>
                                    치어리딩
                                </div>
                                <div className='service__content-txt-sub'>
                                    한국 월드컵 응원단 출신 멤버들이 파워풀한 치어리딩 축하무대를 보여드립니다.
                                </div>
                            </div>
                            <div className={`service__content-txt-item 
                            + ${useScroll('.service__content-txt-item:nth-child(3)', 0.65, 0.35).isShow ? 'opacity-1' : 'opacity-point3'}
                            // 모바일
                            + up--start-mo + ${useScroll('.service__content-txt-item:nth-child(3)', 0.90).isShow ? 'up--end1-mo' : ''}`}
                            >
                                <div className='service__img-wrap-mo'>
                                    <img src= '/img/song03.jpg' decoding="async"/>
                                </div>
                                <div className='service__content-txt-title'>
                                    전통혼례 축하무대
                                </div>
                                <div className='service__content-txt-sub'>
                                    한국 고유의 전통노래에 맞추어 탈춤 및 함사시오 퍼포먼스를 보여드립니다. 이색적인 결혼식을 원한다면 추천드립니다.
                                </div>
                            </div>
                            <div className={`service__content-txt-item + ${useScroll('.service__content-txt-item:nth-child(4)', 0.65, 0.35).isShow ? 'opacity-1' : 'opacity-point3'}
                            // 모바일
                            + up--start-mo + ${useScroll('.service__content-txt-item:nth-child(4)', 0.90).isShow ? 'up--end1-mo' : ''}`}
                            >
                                <div className='service__img-wrap-mo'>
                                    <img src= '/img/song04.jpg' decoding="async"/>
                                </div>
                                <div className='service__content-txt-title'>
                                    트렌디 댄스무대
                                </div>
                                <div className='service__content-txt-sub'>
                                    K-POP, 트렌디 뮤직 등 대중적으로 인기있는 노래를 따라 퍼포먼스 무대를 보여드립니다.
                                </div>
                            </div>
                        </div>
                        <div className='service__content-img-con'>
                            <div className='service__triangle'></div>
                            <div className='service__content-img'>
                                <img src= '/img/song01.jpg' decoding="async"/>
                                <img src= {useScroll('.service__content-txt-item:nth-child(2)', 0.65).isShow ? '/img/song02.jpg' : ''} decoding="async"
                                style={{opacity: useScroll('.service__content-txt-item:nth-child(2)', 0.65).isShow ? '1' : '0'}}
                                />
                                <img src= {useScroll('.service__content-txt-item:nth-child(3)', 0.65).isShow ? '/img/song03.jpg' : ''} decoding="async"
                                style={{opacity: useScroll('.service__content-txt-item:nth-child(3)', 0.65).isShow ? '1' : '0'}}
                                />
                                <img src= {useScroll('.service__content-txt-item:nth-child(4)', 0.65).isShow ? '/img/song04.jpg' : ''} decoding="async"
                                style={{opacity: useScroll('.service__content-txt-item:nth-child(4)', 0.65).isShow ? '1' : '0'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}