import { useEffect, useState } from 'react';
import './../App.css';
import list from './../data/license/list.json'
import Apache2 from '../data/license/Apache2';
import MIT from '../data/license/MIT';
import SILOFL from '../data/license/SILOFL';


export default function License(){
    const licenseList = list.data;

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con' style={{background: 'url(/img/test02.jpg)'}}>
                    <div>LICENSE</div>
                </div>

                <div className='detail-page__btn'>
                    <ul className='detail-page__btn-con'>
                        <li className='detail-page__btn-home'><a href='/'>
                            <img src= '/icons/home.svg' decoding="async"/>
                        </a></li>
                        {/* 현재 페이지 메인메뉴 */}
                        <li className='detail-page__btn-menu'><a>LICENSE</a></li>
                        <li className={`detail-page__btn-submenu`}>
                            {/* 현재 페이지 서브메뉴 */}
                            <a>LICENSE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&#8744;</span>
                            </a>
                            <ul>
                                <li><a href='/review/reason'>감동의이유</a></li>
                                <li><a href='/review/review'>후기모음</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className='section-page'>
                    <div className='section-page__header-con'>
                        <div className='section-page__hedaer-txt-con'>
                            <div>LICENSE</div>
                            <div>본 웹사이트에 사용된 오픈소스 소프트웨어의 라이선스에 대하여 고지합니다.</div>
                        </div>
                    </div>
    
                    <div className='license__con fade-in-self' style={{padding:'0'}}>
                        <ul className='license__grid-con'>
                            {
                                licenseList.map((a, i) => {
                                    return (
                                        <li className='license__grid-item'>
                                            <div className='license__source-name'><a href={a.url}>{a.name}</a></div>
                                            <div className='license__source-url'><a href={a.url}>{a.url}</a></div>
                                            <div className='license__source-type'>{a.type}</div>
                                            <div className='license__source-writer'>{a.writer}</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                        <div className='license__txt-con'>
                            <Apache2 />
                            <MIT />
                            <SILOFL />
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    )
}