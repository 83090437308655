import { useEffect, useState } from 'react';
import './../../../App.css';
import review_data from './../../../data/review.json'

export default function Review(){
    const [clickBtn, setClickBtn] = useState(false);
    const showList = () => {
        setClickBtn(!clickBtn);
    }

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con' style={{background: 'url(/img/test02.jpg)'}}>
                    <div>REVIEW</div>
                </div>

                <div className='detail-page__btn'>
                    <ul className='detail-page__btn-con'>
                        <li className='detail-page__btn-home'><a href='/'>
                            <img src= '/icons/home.svg' decoding="async"/> 
                        </a></li>
                        {/* 현재 페이지 메인메뉴 */}
                        <li className='detail-page__btn-menu'><a>REVIEW</a></li>
                        <li className={`detail-page__btn-submenu + ${ clickBtn ? 'overflow-visible' : ''}`} onClick={showList}>
                            {/* 현재 페이지 서브메뉴 */}
                            <a>후기모음&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&#8744;</span>
                            </a>
                            <ul>
                                <li><a href='/review/reason'>감동의이유</a></li>
                                <li><a href='/review/review'>후기모음</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__title-con'>
                        <div className='detail-page__title-main'>REVIEW</div>
                        <div className='detail-page__title-sub'>고객님들의 진심을 담은 후기모음</div>
                        <div className='txt-line'></div>
                    </div>

                    <ul className='intro-review__img-con'>
                        {review_data.data.map((a,i)=>{
                            return (
                                <li className='intro-review__img-item' style={{backgroundImage:`url(/img/couple0${i+1}.jpg)`}}>
                                    <div className='intro-review__img-txt-con'>
                                        <div className='intro-review__img-txt-title'>{a.title}</div>
                                        <div className='intro-review__img-txt-sub' dangerouslySetInnerHTML={{__html: a.content}}></div>
                                    </div>
                                </li>
                        )})}
                    </ul>
                </div>
            </div>
        </>
    )
}

function GridItem(props){    

    return (
        <>
            <li className='intro__grid-item'>
                <div className='intro__img-box' style={{backgroundImage:'url(/img/test0' + props.a + '.jpg)'}} ></div>
            </li>    
        </>
    )
}