import { useState } from 'react';
import './../App.css';
import Loading from './Loading';
import review_data from './../data/review.json'


export default function Intro(){

    const ratioOfVideo = (16 / 9);
    const ratioOfWeb = (window.innerWidth / window.innerHeight);
    // className={ ratioOfVideo > ratioOfWeb ? 'video-bigger-than-web' : 'video-smaller-than-web' }
    // autoplay=true&loop=true&muted=true&controls=0&quality=480p
    // hw. 영상요청 1- vimeo업로드  2- 16:9비율
    
    const data = [{id: 0, title: '개인정보 수집 및 이용 동의'}] // 약관동의내역
    const [checkItems, setCheckItems] = useState([]); // 약관 체크된 내용
    const selectChecked = (checked, id) => { // 약관 개별 동의
        if (checked) {
          setCheckItems(item => [...item, id]); // 기존 state 내용(item)에 새로운 내용(id)을 추가하는 방식
        } else {
          setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    const portfolioData = [
        ['01', 'https://youtu.be/o_F4nZ7ddiw'],
        ['02', 'https://youtu.be/X2qo370SvD0'],
        ['03', 'https://youtu.be/1CX2smhfhJ0?si=t7wzeTe_FD8wN0bb'],
        ['04', 'https://youtu.be/w03vWlcabHg?si=LE2ApTdSQjD5-6Bt'],
        ['05', 'https://youtu.be/e21VFJuLQOM'],
        ['06', 'https://youtu.be/qTjXKHbw3kA'],
        ['07', 'https://youtu.be/7ksg2t_eZQ0?si=9IJtRmt3Ze-lradd'],
        ['08', 'https://youtu.be/78glNJS94pQ'],
        ['09', 'https://youtu.be/3RNVeuGeOwY?si=bFhlmdvwrZpxzZBm'],
        ['10', 'https://youtu.be/b31q78Wx14U?si=H5y51Jp13shvIUnf'],
        ['11', 'https://youtu.be/awRg5IRAAJI'],
        ['12', 'https://youtu.be/7t9tpyIXsLM?si=j4iUQEdKEXiF_eKn'],
    ]


    return(
        <>
            <Loading />
            <div className='intro-spot' style={{background:'black'}}>
                <div className='intro-spot__con'>
                    {/* hw. vimeo 태그 써서 영상넣기 */}
                    <div className='intro-spot__bg'>
                        <iframe className={ ratioOfVideo > ratioOfWeb ? 'video-bigger-than-web' : 'video-smaller-than-web' } src='https://player.vimeo.com/video/910697523?autoplay=true&loop=true&muted=true&controls=0&quality=1080p'
                        frameborder='0' allowfullscreen>
                        </iframe>
                    </div>

                    <div className='intro-spot__txt-con'>
                        <img src='/img/logo_white03.png' />
                        {/* <div className='intro-spot__txt-title'>H ROAD<br/>WEDDING
                        </div>
                        <div className='intro-spot__txt-sub'>우리를 만나면 웨딩이 특별해집니다
                        </div> */}
                    </div>
                    <div className='intro-spot__arrow'>
                        <img src='/img/arrow.png' width='70px' height='30px' alt='arrow'/>
                    </div>
                </div>
            </div>
            
            {/* ======================================= */}


            <div className='intro-brand' style={{backgroundImage:'url(/img/test02.jpg)'}}>
                <div className='intro-brand__con'>
                    <div className='intro-brand__txt-con'>
                        <p className='intro-brand__txt-title'>웨딩의 순간을 더 아름답게</p>
                        {/* <p className='intro-brand__txt-title'>WEDDING PLAY DIRECTOR</p> */}
                        <p className='intro-brand__txt-sub'>우린 결혼식에 특화된 축가, 사회, 촬영, 디렉팅까지<br/>웨딩에 필요한 부분을 최고의 퀄리티로 제공합니다</p>
                    </div>
                    <div className='intro-brand__btn-wrap'>
                        <span><a href='/company/introduction'>BRAND STORY &nbsp;&#8250;</a></span>
                    </div>
                </div>
            </div>

            {/* ======================================= */}

            <div className='intro-portfolio'>
                <div className='intro-portfolio__con'>
                    <div className='intro-portfolio__txt-con'>
                        <div className='intro-portfolio__txt-title'>WORKS</div>
                        <div className='intro-portfolio__txt-sub'>지금까지 수많은 신랑신부님들에게 감동을 드렸습니다</div>
                    </div>
                    <div className='txt-line'></div>
                    {/* hw. 그리드 안에 vimeo 영상이 들어가게 */}
                    <ul className='intro-portfolio__grid-con'>
                        {
                            portfolioData.map((a, i) => {
                                return <GridItem a={a} i={i} />
                            })
                        }
                    </ul>
                    <div className='intro-portfolio__btn-wrap'>
                        <span><a href='https://www.youtube.com/channel/UC2Edi1ZsZ_ywd30Tu3vE2zQ'>MORE &nbsp;&#8250;</a></span>
                    </div>
                </div>
            </div>

            {/* ======================================= */}

            <div className='intro-service'>
                <div className='intro-service__con'>
                    <div className='intro-sevice__con-left intro-service__box01' style={{backgroundImage:'url(/img/test12.jpg)'}}>
                        <div>WEDDING SONG</div>
                        <div>감동을 전하는 축가와 사회</div>
                    </div>
                    <div className='intro-sevice__con-right'>
                        <div className='intro-service__box02' style={{backgroundImage:'url(/img/test13.jpg)'}}>
                            <div>PHOTO SHOOT</div>
                            <div>소중한 순간을 담는 촬영</div>
                        </div>
                        <div className='intro-service__box03' style={{backgroundImage:'url(/img/test14.jpg)'}}>
                            <div>DIRECTING</div>
                            <div>웨딩의 컨셉을 정하는 디렉팅</div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* ======================================= */}

            <div className='intro-review'>
                <div className='intro-review__con'>
                    <div className='intro-review__txt-con'>
                        <div className='intro-review__txt-title'>REVIEW</div>
                        <div className='intro-review__txt-sub'>고객님들의 진심을 담은 후기모음</div>
                    </div>
                    <div className='txt-line'></div>
                    <ul className='intro-review__img-con'>
                        {review_data.data.map((a,i)=>{
                            return (
                                <li className='intro-review__img-item' style={{backgroundImage:`url(/img/couple0${i+1}.jpg)`}}>
                                    <div className='intro-review__img-txt-con'>
                                        <div className='intro-review__img-txt-title'>{a.title}</div>
                                        <div className='intro-review__img-txt-sub' dangerouslySetInnerHTML={{__html: a.content}}></div>
                                    </div>
                                </li>
                        )})}
                    </ul>
                </div>
            </div>

            {/* ======================================= */}

            <div className='intro-contact' style={{backgroundImage:'url(/img/test16.jpg)'}}>
                <div className='intro-contact__con'>
                    <div className='intro-contact__txt-con'>
                        <div className='intro-contact__txt-title'>CONTACT US</div>
                        <div className='intro-contact__txt-sub'>10초 만에 상담 신청하기</div>
                    </div>

                    <div className='intro-contact__input-con'>
                        <a href='https://open.kakao.com/o/sEZYh5Hc'>
                            <div className={`intro-contact__btn-submit chk-next`}>
                                상담 신청하기
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}


function GridItem(props){    

    return (
        <>
            <li className='intro__grid-item'>
                <a href={props.a[1]} >
                    <div className='intro__img-box' style={{backgroundImage:'url(/img/pf' + props.a[0] + '.jpg)'}} ></div>
                    {/* 호버 시에 나타나는 화면 */}
                    <div className='intro__hover-box'>
                        <img src= '/icons/video.png' decoding="async"/>
                    </div>
                </a>
            </li>    
        </>
    )
}